$(document).ready(function () {
  const inputMarkedModify = 'input-marked-modify';
  const floatingLabelModify = 'floating-label-modify';


  function getTextLabel($from) {
    $from = $($from);
    if (!$from.length) return;

    var $parent = $from.parent();

    return $parent.find('.js-float-label');
  }

  $(document).on('focus', '.js-input-on-change', function() {
    $(this).addClass(inputMarkedModify);

    var $label = getTextLabel($(this));
    if (!$label.length) return;

    $label.addClass(floatingLabelModify);
  });

  $(document).on('input, focusout', '.js-input-on-change', function() {
    if ($(this).val()) return;

    $(this).removeClass(inputMarkedModify);

    var $label = getTextLabel($(this));
    if (!$label.length) return;

    $label.removeClass(floatingLabelModify);
  });

  $('.js-drp-autocomplete').on('hide.bs.dropdown', function () {
    var $inputFitter = $(this).find('.js-input-on-change');
    if ($inputFitter.val()) return;

    $inputFitter.removeClass(inputMarkedModify);

    var $label = getTextLabel($inputFitter);
    if (!$label || !$label.length) return;

    $label.removeClass(floatingLabelModify);
  });

  $(document).on('keypress', '.js-handle-keypress-to-select', function(e) {
    if (e.which === 13 || e.which === 32) {
      $(this).get(0).click();
      e.preventDefault();
    }
  });
});
